import DegIcon from "~/components/atoms/Icons/360degIcon";
import AlertSuccessIcon from "~/components/atoms/Icons/AlertSuccessIcon";
import ArrowLeft from "~/components/atoms/Icons/ArrowLeftIcon";
import ArrowRight from "~/components/atoms/Icons/ArrowRightIcon";
import BackIcon from "~/components/atoms/Icons/BackIcon";
import BellIcon from "~/components/atoms/Icons/BellIcon";
import BrochureIcon from "~/components/atoms/Icons/BrochureIcon";
import CalendarIcon from "~/components/atoms/Icons/CalendarIcon";
import ChatIcon from "~/components/atoms/Icons/ChatIcon";
import CheckedIcon from "~/components/atoms/Icons/CheckedIcon";
import ChevronDownIcon from "~/components/atoms/Icons/ChevronDownIcon";
import ChevronRightIcon from "~/components/atoms/Icons/ChevronRightIcon";
import ChevronsRightIcon from "~/components/atoms/Icons/ChevronsRightIcon";
import CloseIcon from "~/components/atoms/Icons/CloseIcon";
import CloseMenuIcon from "~/components/atoms/Icons/CloseMenuIcon";
import EmailIcon from "~/components/atoms/Icons/EmailIcon";
import FacebookDarkIcon from "~/components/atoms/Icons/FacebookDarkIcon";
import FacebookLightIcon from "~/components/atoms/Icons/FacebookLightIcon";
import FilterIcon from "~/components/atoms/Icons/FilterIcon";
import FingerPrintIcon from "~/components/atoms/Icons/FingerPrintIcon";
import FloorPlanIcon from "~/components/atoms/Icons/FloorPlanIcon";
import GreenCirclePhoneIcon from "~/components/atoms/Icons/GreenCirclePhone";
import GreenPhoneIcon from "~/components/atoms/Icons/GreenPhoneIcon";
import Heart from "~/components/atoms/Icons/Heart";
import ImageIcon from "~/components/atoms/Icons/ImageIcon";
import InstagramDarkIcon from "~/components/atoms/Icons/InstagramDarkIcon";
import InstagramLightIcon from "~/components/atoms/Icons/InstagramLightIcon";
import LightBulbIcon from "~/components/atoms/Icons/LightBulbIcon";
import LocationIcon from "~/components/atoms/Icons/LocationIcon";
import MobileFilterIcon from "~/components/atoms/Icons/MobileFilterIcon";
import MoneyIcon from "~/components/atoms/Icons/MoneyIcon";
import OpenMenuIcon from "~/components/atoms/Icons/OpenMenuIcon";
import Phone from "~/components/atoms/Icons/Phone";
import PhoneIcon from "~/components/atoms/Icons/PhoneIcon";
import PlusIcon from "~/components/atoms/Icons/PlusIcon";
import PriceListIcon from "~/components/atoms/Icons/PriceListIcon";
import PromoCodeIcon from "~/components/atoms/Icons/PromoCodeIcon";
import QuoteIcon from "~/components/atoms/Icons/QuoteIcon";
import RegisterIcon from "~/components/atoms/Icons/RegisterIcon";
import ResetIcon from "~/components/atoms/Icons/Reset";
import ScrollDownIcon from "~/components/atoms/Icons/ScrollDownIcon";
import SearchIcon from "~/components/atoms/Icons/SearchIcon";
import ShowFlatIcon from "~/components/atoms/Icons/ShowFlatIcon";
import StartVideoIcon from "~/components/atoms/Icons/StartVideoIcon";
import SuccessIcon from "~/components/atoms/Icons/SuccessIcon";
import TiktokDarkIcon from "~/components/atoms/Icons/TiktokDarkIcon";
import TiktokLightIcon from "~/components/atoms/Icons/TiktokLightIcon";
import TimesIcon from "~/components/atoms/Icons/TimesIcon";
import VideoIcon from "~/components/atoms/Icons/VideoIcon";
import Whatsapp from "~/components/atoms/Icons/Whatsapp";
import WhatsAppContactIcon from "~/components/atoms/Icons/WhatsAppContactIcon";
import WhatsAppIcon from "~/components/atoms/Icons/WhatsAppIcon";
import WhiteCalendarIcon from "~/components/atoms/Icons/WhiteCalendarIcon";
import { Enum } from "~/utilities/enums/Enum";

export const EIcon = Object.freeze({
  ChatIcon,
  QuoteIcon,
  EmailIcon,
  PhoneIcon,
  WhatsAppContactIcon,
  FilterIcon,
  ScrollDownIcon,
  Heart,
  ArrowLeftIcon: ArrowLeft,
  ArrowRightIcon: ArrowRight,
  BellIcon,
  LocationIcon,
  PlusIcon,
  PromoCodeIcon,
  FacebookLightIcon,
  InstagramLightIcon,
  TiktokLightIcon,
  FloorPlanIcon,
  VideoIcon,
  ImageIcon,
  StartVideoIcon,
  BackIcon,
  CloseMenuIcon,
  OpenMenuIcon,
  FacebookDarkIcon,
  TiktokDarkIcon,
  InstagramDarkIcon,
  WhatsAppIcon,
  ChevronRightIcon,
  DegIcon,
  CalendarIcon,
  ChevronDownIcon,
  TimesIcon,
  SearchIcon,
  CheckedIcon,
  SuccessIcon,
  ChevronsRightIcon,
  AlertSuccessIcon,
  ResetIcon,
  CloseIcon,
  GreenPhoneIcon,
  GreenCirclePhoneIcon,
  Whatsapp,
  Phone,
  MoneyIcon,
  WhiteCalendarIcon,
  BrochureIcon,
  ShowFlatIcon,
  PriceListIcon,
  MobileFilterIcon,
  LightBulbIcon,
  RegisterIcon,
  FingerPrintIcon
});

export type EIcon = Enum<typeof EIcon>;
